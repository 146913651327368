import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { memo } from 'react';
import classes from "./priviousVisits.module.css";
import { useEffect, useState } from "react";
import { getStagesData, visitsGetData } from "../../../axios/api";
import { useAxios } from "../../../hooks/auth";
import VisitsAccordian from "../currentVisits/VisitsAccordian";


function PriviousVisits({ formState }) {
  const [isLoading, setIsLoading] = useState(true)
  const axios = useAxios()
  const isMobile = useMediaQuery('(max-width:800px)');
  const [visitsData, setVisitsData] = useState([])
  useEffect(() => {
    const setVistData = () => {
      const patientId = formState?.patientIdPat || formState?.patientId
      visitsGetData(axios, patientId, 'previousVisit').then(async (res) => {
        if (res?.length !== 0) {
          let newArr = res.map((item) => {
            let stagePayload = {
              patientId: item?.patientId || item?.origPatientId,
              surveyId: item?.surveyId || item?.origSurveyId,
              studyId: item?.studyId || item?.origstudyId,
              orderId: item?.orderId || item?.origOrderId,
              dmeOrderId: item?.dmeOrderId || item?.origDmeOrderId,
            }
            getStagesData(axios, stagePayload)
              .then((response) => {
                if (response?.stages?.length) {
                  item.stages = response?.stages;
                  item.currentStage = response?.currentStage;
                  item.currentStatus = response?.currentStatus;
                }
              })
              .catch((err) => {
                console.error('Error:', err);
              })
              .finally(() => {
                setIsLoading(false);
              });
            return item;
          })
          const visitDataArr = await Promise.all(newArr);
          setVisitsData(visitDataArr);
        }else{
          setIsLoading(false)
        }
      }).catch((err) => {
        setIsLoading(false)
      })
    }
    if (formState?.patientIdPat || formState?.patientId) {
      setVistData()
    }
  }, [])

  if (isLoading) {
    return (
      <Box className={classes.circulaProgressBarContainer}>
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      {
        !visitsData?.length ?
          <Box sx={{ display: 'flex', height: "90vh", width: "100%", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h5">
              Previous visits not associated with selected patients
            </Typography>
          </Box>
          :
          <>
            <Box className={classes.patientNameWrapper} sx={{ flexDirection: isMobile ? "column" : "row" }}>
              <Box>
                <Typography variant='strong' data-testid='patient-name-label'> Patient Name :  </Typography>
                <Typography variant='span' data-testid='patient-name'>{formState?.firstName} {" "} {formState?.lastName} </Typography>
              </Box>
              <Box>
                <Typography variant='strong' data-testid='patient-id-label'> Patient ID :     </Typography>
                <Typography variant='span' data-testid='patient-id'>{formState?.patientNum}</Typography>
              </Box>
 
            </Box>
            <Box className={classes.patientNameWrapper} sx={{ flexDirection: isMobile ? "column" : "row" }}>
                <Box>
                  <Typography variant='strong' data-testid='patient-name-label'> Best Contact Number :  </Typography>
                  <Typography variant='span' data-testid='patient-name'>{formState?.bestPhone}</Typography>
                </Box>
                <Box>
                  <Typography variant='strong' data-testid='patient-id-label'> Cell Phone Number :     </Typography>
                  <Typography variant='span' data-testid='patient-id'>{formState?.cellPhone}</Typography>
                </Box>

              </Box>
            <VisitsAccordian visitsData={visitsData} isPreviousVisits={true} />
          </>
      }
    </>
  );
}

export default memo(PriviousVisits);
