import React, { useEffect, useState } from "react";
import { useAxios } from "../../hooks/auth";
import { CircularProgress, Typography, Box } from "@mui/material";
import { getReports, patientsPostData, reportsGetData } from "../../axios/api";
import MUIDataTable from "mui-datatables";
import { notificationToastError } from "../../utils/notification";
import SearchReport from "../../components/reports/SearchReport";
import { dateConverterDataBase } from "../../utils/dateConverter";
import { Chart } from "react-google-charts";
import dayjs from 'dayjs';
import 'dayjs/locale/en'; // or your preferred locale
import moment from "moment";

const Reports = () => {
  const axios = useAxios();

  const [showData, setshowData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [phpData, setPhpData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);

  const [columnHeaders, setColumnHeaders] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);
  const [canFiltered, setCanFiltered] = useState({});

  const options = {
    title: "Test Report",
    titlePosition: "none",
    allowHtml: true,
  };
  const [formInputState, setFormInputState] = useState({
    startDate: dayjs().startOf('month').toDate(),
    endDate: dayjs().endOf('month').toDate(),
  });

  const [formErrorState, setFormErrorState] = useState({});
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const updateReportInput = (e) => {
    if (e.target.name === "reporttypeId" && e.target.value) {
      setFormErrorState({ ...formErrorState, reporttypeId: false });
    }

    if (e.target.name === "reporttypeId") {
      const filteredReportdata = reportColumns?.filter(
        (item) => item?.reportParam === e.target.value
      );

      if (filteredReportdata?.length) {
        setCanFiltered(filteredReportdata[0]);
      }
    }

    setFormInputState({
      ...formInputState,
      [e.target.id || e.target.name]: e.target.value || "",
    });
  };

  const updateDates = (newValue, fieldIdentifier) => {
    setFormInputState((prevState) => ({
      ...prevState,
      [fieldIdentifier]: newValue,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getReports(axios);
        // console.log("🚀 ~ fetchData ~ res:", res);

        if (res?.length && typeof res !== "string") {
          setReportColumns(res);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getReportHandler = async () => {
    let isValid = true;

    const { reporttypeId, locationId, startDate, endDate, studyTypeId, groupId } = formInputState;

    if (!reporttypeId) {
      isValid = false;

      notificationToastError("Select Report is required.");
      setFormErrorState({ ...formErrorState, reporttypeId: true });
    }
    setIsLoading(true);

    if (isValid) {
      const payload = {
        locationId,
        studyTypeId,
        groupId,
        startDate: startDate && dateConverterDataBase(new Date(startDate)),
        endDate: endDate && dateConverterDataBase(new Date(endDate)),
      };
      reportsGetData(axios, reporttypeId, payload)
        .then((res) => {
          if (res?.length && typeof res !== "string") {
            setPhpData(res);
            const headers = Object.keys(res[0]);
            setColumnHeaders(headers);
          } else {
            setColumnHeaders([]);
          }
        })
        .catch((err) => {
          console.log("catch block", err?.message);
          notificationToastError("Something went wrong!");
          setColumnHeaders([]);
        })
        .finally(() => {
          setCurrentReport(canFiltered?.reportName);
          setIsLoading(false);
          setshowData(true);
        });
    }
  };

  var dataWithHeaders = phpData?.map((item) =>
    columnHeaders?.map((header) => item[header])
  );

  const columns = columnHeaders?.map((header) => ({ name: header }));

  // const chartData = [
  //   ["City", "2010 Population"],
  //   ["New York City, NY", 8175000],
  //   ["Los Angeles, CA", 3792000],
  //   ["Chicago, IL", 2695000],
  //   ["Houston, TX", 2099000],
  //   ["Philadelphia, PA", 1526000],
  // ];

  // const optionsChart = {
  //   chart: {
  //     // title: "Population of Largest U.S. Cities",
  //   },
  //   hAxis: {
  //     title: "Total Population",
  //     minValue: 0,
  //   },
  //   vAxis: {
  //     title: "City",
  //   },
  //   // bars: "horizontal",
  //   axes: {
  //     y: {
  //       0: { side: "right" },
  //     },
  //   },
  // };

  const patientView = (rowData) => {
    const payload = {
      patientNum: rowData,
    };
    patientsPostData(axios, "getPatientId ", payload)
      .then((res) => {
        if (res) {
          let patientId = res?.data[0]?.patientId
          localStorage.setItem('patientId', patientId)
          window.open(`/patient-view?isnewtab=true`, '_blank');
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const dosIndex = columns.findIndex(column => column.name === "DOS");

  // if (dosIndex !== -1) {
  //   dataWithHeaders = dataWithHeaders.map(row => {
  //     const dosValue = row[dosIndex];
  
  //     if (dosValue) {
  //       row[dosIndex] = dosValue ? new Date(dosValue) : "";
  //     }
  
  //     return row;
  //   });
  // }

  return (
    <>
      {/* <div>
        <Button
          variant="contained"
          onClick={() => handleShowReport("turnaround")}
        >
          Turnaround Report
        </Button>
        {"  "}
        <Button
          variant="contained"
          onClick={() => handleShowReport("ordersReceived")}
        >
          Orders Report
        </Button>
      </div> */}

      <SearchReport
        formInputState={formInputState}
        formErrorState={formErrorState}
        updateDates={updateDates}
        submitHandler={getReportHandler}
        updateReportInput={updateReportInput}
        reportColumns={reportColumns}
        canFiltered={canFiltered}
      />

      {/* chartReport */}

      {/* {canFiltered?.chartReport ? (
        <Chart
          chartType="Bar"
          width="100%"
          height="400px"
          data={chartData}
          options={optionsChart}
        />
      ) : null} */}

      {/* <Chart
        chartType="Bar"
        // data={[
        //   ["Date", "Number"],
        //   ["11/11/2022", 1],
        //   ["11/12/2022", 3],
        //   ["10/11/2022", 2],
        // ]}
        data={chartData}
        options={optionsChart}
        width="100%"
        height="400px"
        legendToggle
      /> */}

      {showData ? (
        <>
          <Box sx={{ marginTop: "1rem", ml: 1.5 }}>
            <Typography variant="h6" data-testid="appoinment-text">
              {currentReport}
            </Typography>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            {
              // isLoading ? (
              //   <Box
              //     sx={{
              //       display: "flex",
              //       height: "90vh",
              //       width: "100%",
              //       justifyContent: "center",
              //       alignItems: "center",
              //     }}
              //   >
              //     <CircularProgress />
              //   </Box>
              // ) :
              canFiltered?.tableReport ? (
                <MUIDataTable
                  className="dsadadadasdsad"
                  data={dataWithHeaders}
                  columns={columns.map(column => {
                    if (column.name === "Patient ID") {
                      return {
                        ...column,
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            const patientId = value;
                            return (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  color: "#715d9c",
                                }}
                                onClick={() => {
                                  patientView(value);
                                }}
                              >
                                {patientId}
                              </Box>
                            );
                          },
                        },
                      };
                    } if (column.name === "DOS") {
                      const dosColumn = columns.find(column => column.name === "DOS");
                      const dosIndex = columns.indexOf(dosColumn);
                      return {
                        ...column,
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            const dateofservice = tableMeta.rowData[dosIndex]
                              ? moment(tableMeta.rowData[dosIndex]).format("MM/DD/YYYY")
                              : "";
                            return <>{dateofservice}</>;
                          },
                        },
                      };
                    } else {
                      return column;
                    }
                  })}
                  options={{
                    filterType: 'multiselect',
                    selectableRows: 'none',
                    responsive: "vertical",
                    rowsPerPage: 100,
                    customSort: (data, colIndex, order) => {
                      const columnFlag = columns.some(column => column.name === "Interpretation Finalized");
                      const lastTwoRows = columnFlag ? data.slice(-2) : data;
                      const sortArray = columnFlag ? data.slice(0, -2) : data;
                      
                      if (columns[colIndex].name === ("DOS" || "DOB" || "Scheduled" || "HST Upload Date" || "Scored" || "Order Date" || "Completed DOS")) {
                        const sortedData = sortArray.sort((a, b) => {
                          const dateA = new Date(a.data[colIndex]);
                          const dateB = new Date(b.data[colIndex]);
                    
                          if (order === 'asc') {
                            return dateA - dateB;
                          } else {
                            return dateB - dateA;
                          }
                        });
                    
                        const result = columnFlag ? [...sortedData, ...lastTwoRows] : [...sortedData];
                        return result;
                      }else{
                        const sortedData = sortArray.sort((a, b) => {
                          const aValue = isNaN(a.data[colIndex]) ? a.data[colIndex].toString().toLowerCase() : a.data[colIndex];
                          const bValue = isNaN(b.data[colIndex]) ? b.data[colIndex].toString().toLowerCase() : b.data[colIndex];
                      
                          const compareResult = order === 'desc'
                            ? isNaN(a.data[colIndex]) ? bValue && bValue.localeCompare(aValue) : bValue - aValue
                            : isNaN(a.data[colIndex]) ? aValue && aValue.localeCompare(bValue) : aValue - bValue;
                      
                          return compareResult;
                        });
                      
                        const result = columnFlag ? [...sortedData, ...lastTwoRows] : [...sortedData];
                        return result;
                      }
                    },
                    download: true,
                    onDownload: (buildHead, buildBody, columns, data) => {
                      const csv = `${buildHead(columns)}\n${buildBody(data)}`;

                      const csvContent = "\uFEFF" + csv;

                      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

                      const url = URL.createObjectURL(blob);

                      const link = document.createElement("a");
                      link.href = url;
                      link.download = "tableDownload.csv";
                      document.body.appendChild(link);
                      link.click();

                      document.body.removeChild(link);
                      URL.revokeObjectURL(url);

                      return false;
                    },
                  }}
                />
              ) : null
            }
          </Box>
        </>
      ) : (
        <MUIDataTable
          title={"Report List"}
          data={reportColumns}
          className="mui-data-table-files"
          columns={[
            {
              name: "reportName",
              label: "Report Name",
              options: {
                customBodyRender: (value) => <div style={{ textAlign: "left" }}>{value}</div>,
              },
              // options: { display: false, viewColumns: false, filter: false, sort: false },
            },
            {
              name: "description",
              label: "Description",
              options: {
                customBodyRender: (value) => <div style={{ textAlign: "left" }}>{value}</div>,
              },
            },
          ]}
          options={{
            selectableRows: 'none', // <===== will turn off checkboxes in rows
            filterType: 'multiselect',
          }}
        />
      )}
    </>
  );
};

export default Reports;
