import { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { Button, Container } from "../../node_modules/@mui/material/index";
import RoleView from "../pages/RoleView";
import GroupView from "../pages/GroupView";
import { useAxios } from "../hooks/auth";
import { getConfigurationData, getReports, getSwitchUserData, handleDeleteConfiguration, pulldownsPostData, switchUserLogin } from "../axios/api";
import useIsPrevileges from "../hooks/useRoleAndPrivileges";
import { priviledgesSubTab } from "../constants/tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../utils/notification";
import { ToastContainer } from "react-toastify";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../constants/sideBarwidth";
import { adminPostData, adminsGetData,handleAddConfiguration } from "../axios/api";
import DocumentTable from "./adminComponent/documents/documentTable/DocumentTable";
import DocumentDialog from "./adminComponent/documents/documentDialog/DocumentDialog";
import { updateAdminPath, updateAdminTabName } from "../store/slices/adminPathSlice";
import useIsGroupId from "../hooks/groupIdInfo";
import ReportsView from "../pages/ReportsView";
import useIsUserId from "../hooks/userIdInfo";
import { useCookies } from "react-cookie";
import ConfigurationDialog from "./adminComponent/Configuration/ConfigurationDialog";
import ConfigAccordian from "./adminComponent/Configuration/ConfigAccordian";
import { configurationObject } from "./adminComponent/Configuration/ConfigurationObject";

export default function Admin() {
  const privelegesArray = useIsPrevileges();
  const isMobile = useMediaQuery("(max-width:800px)");
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const { tabValue, tabName } = useSelector((state) => state.adminPathSelector);
  const [viewRoleState, setViewRoleState] = useState({});
  const [viewGroupState, setViewGroupState] = useState({});
  const [viewDocuments, setViewDocuments] = useState({});
  const [rowDataUsers, setRowDataUsers] = useState([]);
  const [rowDataLocations, setRowDataLocations] = useState([]);
  const [rowDataRoles, setRowDataRoles] = useState([]);
  const [privilegesData, setPrivilegesData] = useState([]);
  const [rowDataGroups, setRowDataGroups] = useState([]);
  const [facilitiesData, setfacilitiesData] = useState([]);
  const [rowDataDocument, setRowDataDocument] = useState([]);
  const [rowDataInsurance, setRowDataInsurance] = useState([]);
  const [rowDataReports, setRowDataReports] = useState([]);
  const [smsReports, setSmsReports] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewReports, setViewReports] = useState({});
  const [superUsers, setSuperUsers] = useState([]);
  const userGroupId = useIsGroupId();
  const userId = useIsUserId();
  const oldUserId = useIsUserId();
  const [cookies, setCookies, removeCookie] = useCookies();
  const axios = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [configurationArray, setConfigurationArray] = useState([{ name: "Study Types" }, { name: "HST Brands" }, { name: "HST Devices" }, { name: "DME Brands" }, { name: "DME Models" }, { name: "File Categories" }, { name: "Reasons for Rescheduling" }, { name: "Reason for Canceling Study" }, { name: "Reasons for Incomplete Appt" }, { name: "Reasons for Authorization Denial" }, { name: "Reasons to Decline Study" },{ name: "SMS / Email Content"}])
    
  const addUser = () => {
    navigate("/user-view");
  };

  const addGroup = (type) => {
    setViewGroupState({
      showViewGroup: true,
      facility: type == "facility" ? 1 : 0,
    });
  };

  const addRole = () => {
    setViewRoleState({ showViewRole: true, privilegesData });
  };

  const addLocation = () => {
    navigate("/location-view");
  };

  const locationView = (rowData) => {
    navigate(`/location-view`, { state: { row: rowData } });
  };

  const insuranceView = (InsuraceID) => {
    const filterInssuranceData = rowDataInsurance?.filter(
      (item) => item.insuranceId === InsuraceID
    );
    navigate(`/insurance-view`, { state: { row: filterInssuranceData[0] } });
  };

  const addInsurance = () => {
    navigate("/insurance-view");
  };

  const groupView = (id, name, noLoginPractice, facility,groupHeaderSleepStudy,groupHeaderDMEOrder,logoFileId) => {
    setViewGroupState({
      showViewGroup: true,
      groupId: id,
      groupName: name,
      noLoginPractice: noLoginPractice,
      facility: facility,
      groupHeaderSleepStudy: groupHeaderSleepStudy,
      groupHeaderDMEOrder: groupHeaderDMEOrder,
      logoFileId:logoFileId
    });
  };

  const [dialogOpen, setDialogOpen] = useState({ flag: false, name: "",data:{} });  // State to control dialog visibility

  // Function to open the dialog
  const handleOpenDialog = (name,rowData) => {
    setDialogOpen({ ...dialogOpen, flag: true, name: name, rowData: rowData });
    setFormErrorState({});
  };

  const handleDelete = (itemName, rowData) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const deleteInfo = configurationObject[itemName];
    const { idField, tableName } = deleteInfo;
    const deletePayload = { id: rowData?.[idField], tableName }
  
    handleDeleteConfiguration(axios, deletePayload)
      .then((res) => {
        if (res?.response === "1") {
          notificationToastSuccess(res?.message);
          handleConfigurationData();
        } else {
          notificationToastError(res?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
  }

  // Function to close the dialog
  const handleCloseDialog = (e, reason) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      setFormErrorState({});
      setFormState({});
      setDialogOpen({ ...dialogOpen, flag: false });
    }
  };

  const [reportColumns, setReportColumns] = useState([]);

  const roleView = (row) => {
    let privilegesObj = {};
    let privileges = {};
    let roleData = {};
    if (rowDataRoles?.length) {
      const filterRole = rowDataRoles?.filter(
        (item) => item?.roleId === row[0]
      )?.[0];
      privilegesObj = filterRole?.rolePriv;
      const keysArray = Object.keys(privilegesObj);
      keysArray.sort();
      keysArray.forEach((key) => {
        privileges[key] = privilegesObj[key];
      });
      roleData = {
        role: filterRole?.role,
        roleId: filterRole?.roleId,
      };
    }
    setViewRoleState({
      showViewRole: true,
      roleData,
      privileges,
      privilegesData,
    });
  };
  const closeViewRoleDialog = (e, reason) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      setViewRoleState({});
      setRefreshData(!refreshData);
    }
  };

  const closeViewGroupDialog = (e, reason) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      setViewGroupState({});
      setRefreshData(!refreshData);
    }
  };
  const closeDocumentDialog = (e, reason) => {
    if (reason === "backdropClick") {
      e.preventDefault();
    } else {
      setViewDocuments({});
      // setRefreshData(!refreshData);
    }
  };

  const deleteRecord = (id, tableName, idColumn) => {
    const yesToDelete = window.confirm("Are you sure you want to delete it? ");
    if (!yesToDelete) return;
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    adminPostData(axios, "deleteRecord", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
        setRefreshData(!refreshData);
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    // navigate(`/delete-record`, {
    //   state: { id: id, tableName: tableName, idColumn: idColumn },
    // });
  };

  const userView = (rowData) => {
    pulldownsPostData(axios, "groupPulldown", { }).then((res) => {
      if (res?.data) {
        let noLoginUser = res.data.find((i) => i.groupId == rowData[16])?.noLoginPractice
        navigate(`/user-view`, { state: { row: rowData, noLoginPractice: noLoginUser } });
      }
    }).catch((err) => {
      console.log(err);
    })
  };

  // document handler
  const addDocuments = () => {
    setViewDocuments({ isDocumentOpen: true });
  };
  useEffect(() => {
    async function setDataFirstTime() {
      adminsGetData(axios)
        .then((res) => {
          //console.log("res====>",res);
          if (res) {
            setRowDataUsers(res["0"]);
            setRowDataGroups(res["1"]);
            setRowDataLocations(res["2"]);
            setRowDataRoles(res["3"]);
            setRowDataInsurance(res["4"]);
            setRowDataDocument(res[6]);
            setPrivilegesData(res["7"]);
            setfacilitiesData(res["8"]);
            setRowDataReports(res["9"]);
            setSmsReports(res["10"]);
            // console.log("RowDataUsers = ", rowDataUsers(res["0"]));
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    setDataFirstTime();
  }, [refreshData]);

  useEffect(() => {
    if (tabValue === 9 && tabName === "Switch User") {
      getSwitchUserData(axios, userId)
        .then((res) => {
         
            if(res?.data?.length){
              setSuperUsers(res?.data)
            }
      
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
    }
    if (tabName === 'Configuration') {
      handleConfigurationData();
    }
  }, [tabValue,tabName])

  const [formState, setFormState] = useState({
    studyType: "",
    inLab: false,
    hst: false,
    dme: false,
    multiNight: "",
    deviceName:"",
    hstBrandId:"",
    brandName:"",
    model:"",
    category:"",
    reason:"",
    cancelReason:"",
    incompleteReason:"",
    authDenialReason:"",
    studyDeclineReason:""
  });

  const [formErrorState, setFormErrorState] = useState({
    studyType: "",
    deviceName: ""
  });

  const handleConfigurationData = () => {
    getConfigurationData(axios)
      .then((res) => {
        setConfigurationArray([
          { name: "Study Types", data: res[0] },
          { name: "HST Brands", data: res[1] },
          { name: "HST Devices", data: res[2] },
          { name: "DME Brands", data: res[3] },
          { name: "DME Models", data: res[4] },
          { name: "File Categories", data: res[5] },
          { name: "Reasons for Rescheduling", data: res[6] },
          { name: "Reason for Canceling Study", data: res[7] },
          { name: "Reasons for Incomplete Appt", data: res[8] },
          { name: "Reasons for Authorization Denial", data: res[9] },
          { name: "Reasons to Decline Study", data: res[10] },
          { name: "SMS / Email Content", data: res[11] }
        ]);
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
  }

  // Handle input changes for text field and checkboxes
  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    // Mapping dialog names to fields and validation logic
    const dialogFields = {
      "Study Types": { fields: ["studyType", "inLab", "hst", "dme", "multiNight"], validation: formState => !formState.studyType || (!formState.inLab && !formState.hst && !formState.dme), paramName: "saveStudyType" },
      "HST Brands": { fields: ["brandName"], validation: formState => !formState.brandName, paramName: "saveHstBrand" },
      "HST Devices": { fields: ["deviceName", "hstBrandId"], validation: formState => !formState.deviceName || !formState.hstBrandId, paramName: "saveHstDevice" },
      "DME Brands": { fields: ["brandName"], validation: formState => !formState.brandName, paramName: "saveDmeBrand" },
      "DME Models": { fields: ["model", "dmeBrandId"], validation: formState => !formState.model || !formState.dmeBrandId, paramName: "saveDmeModel" },
      "File Categories": { fields: ["category", "patientPortal"], validation: formState => !formState.category, paramName: "saveFileCategory" },
      "Reasons for Rescheduling": { fields: ["reason"], validation: formState => !formState.reason, paramName: "saveReasonApptRescheduling" },
      "Reason for Canceling Study": { fields: ["reason", "inLab", "hst", "dme"], validation: formState => !formState.reason || (!formState.inLab && !formState.hst && !formState.dme), paramName: "saveReasonApptCanceling" },
      "Reasons for Incomplete Appt": { fields: ["reason"], validation: formState => !formState.reason, paramName: "saveReasonIncompleteStudy" },
      "Reasons for Authorization Denial": { fields: ["reason"], validation: formState => !formState.reason, paramName: "saveReasonAuthDenial" },
      "Reasons to Decline Study": { fields: ["reason"], validation: formState => !formState.reason, paramName: "saveReasonDeclining" },
      "SMS / Email Content": { fields: ["category","content"], validation: formState => !formState.category, paramName: "saveContents" }
    };

    const dialog = dialogFields[dialogOpen.name];
    // If the dialog exists and validation fails, set errors and return
    if (dialog && dialog.validation(formState)) {
      setFormErrorState(Object.fromEntries(dialog.fields.map(field => [field, { error: true }])));
      if ((dialogOpen.name === "Study Types" || dialogOpen.name === "Reason for Canceling Study") && (!formState.inLab && !formState.hst && !formState.dme)) {
        notificationToastError("Select at least one of the In-Lab, HST, and DME checkboxes.")
        return;
      }
      return;
    }
    
    
    // Helper function to handle field transformations (e.g., converting to number)
    const handleFieldTransformations = (formState, field) => {
      if (formState.hasOwnProperty(field)) {
        // For specific fields that need to be converted to numbers
        return ["inLab", "hst", "dme", "multiNight", "patientPortal"].includes(field) ? Number(formState[field]) : formState[field];
      }
      return undefined;
    };

    // Create payload dynamically based on the fields and handle transformations
    let payload = dialog.fields.reduce((acc, field) => {
      const value = handleFieldTransformations(formState, field);
      if (value !== undefined) acc[field] = value;
      return acc;
    }, {});

    const editInfo = configurationObject[dialogOpen.name];
    const { idField } = editInfo;

    payload = dialogOpen?.rowData ? { ...payload, [idField]: dialogOpen?.rowData?.[idField] } : payload
    const data = await handleAddConfiguration(axios, payload, dialog?.paramName);
    if (data?.response === "1") {
      notificationToastSuccess(data?.message)
      handleConfigurationData();
    }
    // Proceed with submit logic if no errors
    handleCloseDialog();
  };
  
  // const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    // setTabValue(newValue);
    dispatch(updateAdminPath(newValue));
    dispatch(updateAdminTabName(event.target.textContent));
  };

  const reportView = (reportData) => {
    setViewReports({
      ...viewReports,
      openFlag: true,
      reportName: reportData[0],
      reportId: reportData[3],
      assignedRoleId: reportData[4]
    });
  };

  const patientView = (patientId) => {
    localStorage.setItem('patientId', patientId)
    window.open(`/patient-view?isnewtab=true`, '_blank');
  };

  const closeViewReportsDialog = () => {
    setViewReports({
      ...viewReports,
      openFlag: false,
    });
  };

  const handleSwitchUser = (email,userId) =>{
    switchUserLogin(axios, { email: email, userId: userId, oldUserId: oldUserId })
        .then((res) => {
     
          if (res?.data?.response === "0") {
            notificationToastError(res?.data?.message);
          } else {
            if (res?.data && res?.data?.jwt) {
               setCookies("token", res?.data?.jwt); // your token
               navigate('/dashboard');
              dispatch(updateAdminPath(0))
          }
        }
        })
        .catch((err) => {
          notificationToastError(err?.message);
        });
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const filterPrivileges = priviledgesSubTab?.filter((elem) => {
    return privelegesArray?.privileges?.subtab?.some((ele, index) => {
      if (elem.privileges === ele) {
        return elem;
      }
    });
  });
  const userIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "users"
  );
  const groupIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "pracGroups"
  );
  const facilityIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "facilities"
  );
  const locationIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "locations"
  );
  const roleIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "roleAccess"
  );
  const insuranceIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "insurances"
  );
  const documentIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "documents"
  );
  const reportIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "reports"
  );
  const smsIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "incomingsms"
  );
  const superIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "super"
  );
  const configurationIndex = filterPrivileges?.findIndex(
    (element) => element?.privileges === "configuration"
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "90vh",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Container
      sx={{
        maxWidth: !isMobile
          ? isCollapsed
            ? `calc(100vw - ${collapsedDrawerWidth}px) !important`
            : `calc(100vw - ${openDrawerWidth}px) !important`
          : "100%",
        height: "100%",
      }}
    >
      <>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            visibleScrollbar={false}
            // orientation={isMobile ? "horizontal" : "}
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile={true}
            sx={{
              ".MuiTabs-flexContainer": {
                justifyContent: "flex-start",
              },
              ".MuiTabs-scroller": {
                marginLeft: "10px",
              },
            }}
          >
            {filterPrivileges?.map((item, index) => {
              return (
                <Tab key={item?.subTab} label={item?.subTab} index={index} />
              );
            })}{" "}
            {/* { privelegesArray?.privileges?.subtab?.includes('users') &&  <Tab label="Users" index={0} />} 
            {  privelegesArray?.privileges?.subtab?.includes('pracGroups') &&  <Tab label="Groups" index={1} />}
             { privelegesArray?.privileges?.subtab?.includes('locations') && <Tab label="Locations" index={2} />} 
             { privelegesArray?.privileges?.subtab?.includes('roleAccess') &&  <Tab label="Roles" index={3} />}
             {privelegesArray?.privileges?.subtab?.includes('insurances') &&  <Tab label="Insurance Carrier" index={4} />} */}
          </Tabs>
          {/* </Box> */}
          <TabPanel value={tabValue} index={userIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={addUser}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD User
                </Button>
              </div>
            </Box>

            <MUIDataTable
              data={rowDataUsers}
              columns={[
                {
                  name: "userId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "lastName",
                  label: "Last name",
                },
                {
                  name: "firstName",
                  label: "First Name",
                },
                { name: "email", label: "Email" },
                {
                  name: "phoneNum",
                  label: "Phone",
                },
                {
                  name: "uGroupNames",
                  label: "Group(s)",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const generateStr = tableMeta.rowData[5]; // Your string
                      const generateArray = generateStr
                        ?.split(",")
                        .map((item) => item.trim().split("/")[0]);

                      return (
                        <div style={{ textAlign: "initial" }}>
                          <ul style={{ listStyle: "none" }}>
                            {generateArray?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "role",
                  label: "Role",
                },
                {
                  name: "middleName",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "address1",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "address2",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "city",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  }, // 10
                },
                {
                  name: "state",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "zip",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "twoFactorEnabled",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "emailNotification",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "smsNotification",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "uGroups",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "roleId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "followUpOrder",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "hstCashPricing",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "prefInterpPhysId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                }, // 20
                {
                  name: "followingPhysId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "inLab",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "hst",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "uGroups",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "mobileNum",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "privileged",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },

                {
                  name: "title",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "credentials",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "noLoginPractice",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },//29
                {
                  name: "emailNotAvailable",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "preferredHstBrandId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },//31
                {
                  name: "Actions",
                  label: "Actions",

                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={userView.bind(null, tableMeta.rowData)}
                          >
                            View
                          </Button>
                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "users",
                                "userId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataUsers?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={groupIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={() => addGroup("group")}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Group
                </Button>
              </div>
            </Box>
            <MUIDataTable
              data={rowDataGroups}
              columns={[
                {
                  name: "groupId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupName",
                  label: "Group Name",
                },
                {
                  name: "groupType",
                  label: "Group Type",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "locations",
                  label: "Locations",
                  options: {
                    setCellProps: () => ({
                      style: { maxWidth: "300px" },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const generateStr = tableMeta.rowData[3]; // Your string
                      const generateArray = generateStr
                        ?.split(",")
                        .map((item) => item.trim().split("/")[0]);

                      return (
                        <div style={{ textAlign: "initial" }}>
                          <ul style={{ listStyle: "none" }}>
                            {generateArray?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "groupUsers",
                  label: "Users",
                  options: {
                    setCellProps: () => ({
                      style: { maxWidth: "400px" },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const generateStr = tableMeta.rowData[4];
                      const generateArray = generateStr?.split(" / , ");
                      return (
                        <div>
                          <ul
                            style={{
                              listStyleType: "none",
                              width: "max-content",
                            }}
                          >
                            {generateArray?.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item.includes("/")
                                    ? item.slice(0, -2)
                                    : item}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "name",
                  label: "Created By",
                },
                {
                  name: "facility",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "noLoginPractice",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupHeaderSleepStudy",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupHeaderDMEOrder",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "logoFileId",
                  label: "logoFileId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={groupView.bind(
                              null,
                              tableMeta.rowData ? tableMeta.rowData[0] : "",
                              tableMeta.rowData ? tableMeta.rowData[1] : "",
                              tableMeta.rowData ? tableMeta.rowData[7] : "",
                              tableMeta.rowData ? tableMeta.rowData[6] : "",
                              tableMeta.rowData ? tableMeta.rowData[8] : "",
                              tableMeta.rowData ? tableMeta.rowData[9] : "",
                              tableMeta.rowData ? tableMeta.rowData[10] : "",
                            )}
                          >
                            Edit
                          </Button>

                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "pracGroups",
                                "groupId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataUsers?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>

          {/* facilities tab */}

          <TabPanel value={tabValue} index={facilityIndex}>
            <Box
              sx={{ margin: "16px 0px" }}
              onClick={() => addGroup("facility")}
            >
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Facility
                </Button>
              </div>
            </Box>
            <MUIDataTable
              data={facilitiesData}
              columns={[
                {
                  name: "groupId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupName",
                  label: "Facility Name",
                },
                {
                  name: "groupType",
                  label: "Facility Type",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "locations",
                  label: "Locations",
                  options: {
                    setCellProps: () => ({
                      style: { maxWidth: "300px" },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const generateStr = tableMeta.rowData[3]; // Your string
                      const generateArray = generateStr
                        ?.split(",")
                        .map((item) => item.trim().split("/")[0]);

                      return (
                        <div style={{ textAlign: "initial" }}>
                          <ul style={{ listStyle: "none" }}>
                            {generateArray?.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "groupUsers",
                  label: "Users",
                  options: {
                    setCellProps: () => ({
                      style: { maxWidth: "400px" },
                    }),
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const generateStr = tableMeta.rowData[4];
                      const generateArray = generateStr?.split(" / , ");
                      return (
                        <div>
                          <ul
                            style={{
                              listStyleType: "none",
                              width: "max-content",
                            }}
                          >
                            {generateArray?.map((item, index) => {
                              return (
                                <li key={index}>
                                  {item.includes("/")
                                    ? item.slice(0, -2)
                                    : item}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    },
                  },
                },
                {
                  name: "name",
                  label: "Created By",
                },
                {
                  name: "facility",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "noLoginPractice",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupHeaderSleepStudy",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupHeaderDMEOrder",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "logoFileId",
                  label: "logoFileId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={groupView.bind(
                              null,
                              tableMeta.rowData ? tableMeta.rowData[0] : "",
                              tableMeta.rowData ? tableMeta.rowData[1] : "",
                              tableMeta.rowData ? tableMeta.rowData[7] : "",
                              tableMeta.rowData ? tableMeta.rowData[6] : "",
                              tableMeta.rowData ? tableMeta.rowData[8] : "",
                              tableMeta.rowData ? tableMeta.rowData[9] : "",
                              tableMeta.rowData ? tableMeta.rowData[10] : "",
                            )}
                          >
                            Edit
                          </Button>

                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "pracGroups",
                                "groupId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataUsers?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={locationIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={addLocation}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Location
                </Button>
              </div>
            </Box>

            <MUIDataTable
              data={rowDataLocations}
              columns={[
                {
                  name: "locationId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "location",
                  label: "Location Name",
                },
                {
                  name: "address1",
                  label: "Address1",
                },

                {
                  name: "city",
                  label: "City",
                },
                {
                  name: "state",
                  label: "State",
                },
                {
                  name: "zip",
                  label: "Zip",
                },
                {
                  name: "phone",
                  label: "Phone",
                },
                {
                  name: "fax",
                  label: "Fax",
                },
                {
                  name: "groupName",
                  label: "Group",
                },
                {
                  name: "address2",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "groupId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={locationView.bind(null, tableMeta.rowData)}
                          >
                            View
                          </Button>
                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "locations",
                                "locationId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataLocations?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={roleIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={addRole}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Role
                </Button>
              </div>
            </Box>
            <MUIDataTable
              data={rowDataRoles}
              columns={[
                {
                  name: "roleId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "role",
                  label: "Role",
                },
                {
                  name: "priveleges",
                  label: "Privileges",
                  options: {
                    setCellProps: () => ({
                      style: { maxWidth: "300px" },
                    }),
                  },
                },
                {
                  name: "name",
                  label: "Created By",
                },

                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={roleView.bind(null, tableMeta.rowData)}
                          >
                            Edit
                          </Button>
                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "roles",
                                "roleId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataRoles?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={insuranceIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={addInsurance}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Insurance
                </Button>
              </div>
            </Box>

            <MUIDataTable
              data={rowDataInsurance}
              columns={[
                {
                  name: "insuranceId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "planName",
                  label: "Insurance Carrier",
                },
                {
                  name: "address1",
                  label: "Address1",
                },

                {
                  name: "city",
                  label: "City",
                },
                {
                  name: "state",
                  label: "State",
                },
                {
                  name: "zip",
                  label: "Zip",
                },
                {
                  name: "phone",
                  label: "Phone",
                },
                {
                  name: "fax",
                  label: "Fax",
                },
                {
                  name: "epayerId",
                  label: "Epayer Id",
                },
                {
                  name: "address2",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },

                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={insuranceView.bind(
                              null,
                              tableMeta.rowData[0]
                            )}
                          >
                            View
                          </Button>
                          <Box>
                            <Button
                              sx={{ marginTop: "1rem" }}
                              variant="contained"
                              onClick={deleteRecord.bind(
                                null,
                                tableMeta.rowData ? tableMeta.rowData[0] : "",
                                "insurances",
                                "insuranceId"
                              )}
                            >
                              Delete
                            </Button>
                          </Box>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
                responsive: "vertical",
                // tableBodyHeight:rowDataInsurance?.length && "calc(100vh - 200px)",
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={reportIndex}>
            <MUIDataTable
              title={"Report List"}
              data={rowDataReports}
              className="mui-data-table-files"
              columns={[
                {
                  name: "reportName",
                  label: "Report Name",
                  options: {
                    customBodyRender: (value) => (
                      <div style={{ textAlign: "left" }}>{value}</div>
                    ),
                  },
                  // options: { display: false, viewColumns: false, filter: false, sort: false },
                },
                {
                  name: "description",
                  label: "Description",
                  options: {
                    customBodyRender: (value) => (
                      <div style={{ textAlign: "left" }}>{value}</div>
                    ),
                  },
                },
                {
                  name: "assignedRoles",
                  label: "Assigned Roles",
                  options: {
                    customBodyRender: (value) => (
                      <div style={{ textAlign: "left" }}>{value}</div>
                    ),
                  },
                },
                {
                  name: "reportId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "assignedRoleId",
                  options: {
                    display: false,
                    viewColumns: false,
                    filter: false,
                  },
                },
                {
                  name: "Actions",
                  label: "Actions",
                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={reportView.bind(
                              null,
                              tableMeta.rowData
                            )}
                          >
                            Edit
                          </Button>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={smsIndex}>
            <MUIDataTable
              title={"Incoming SMS List"}
              data={smsReports}
              className="mui-data-table-files"
              columns={[
                {
                  name: "body",
                  label: "Incoming SMS",
                },
                {
                  name: "fromNum",
                  label: "Received From",
                },
                {
                  name: "receivedOn",
                  label: "Received On",
                },
                {
                  name: "patientId",
                  options: { display: false, viewColumns: false, filter: false },
                },
                {
                  name: "patientNum",
                  label: "Patient",
                  options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                      const patientId = value;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#715d9c",
                          }}
                          onClick={() => {
                            patientView(tableMeta.rowData[3]);
                          }}
                        >
                          {patientId}
                        </Box>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
              }}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={documentIndex}>
            <Box sx={{ margin: "16px 0px" }} onClick={addDocuments}>
              <div>
                <Button variant="outlined" size="medium">
                  + ADD Documents/Forms
                </Button>
              </div>
            </Box>
            <DocumentTable
              rowDataDocument={rowDataDocument}
              setViewDocuments={setViewDocuments}
              setRefreshData={setRefreshData}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={superIndex}>
            <MUIDataTable
              title={"Switch Users"}
              data={superUsers}
              className="mui-data-table-files"
              columns={[
                {
                  name: "userId",
                  label: "userId",
                  options: { display: false, viewColumns: false, filter: false },
                },
                {
                  name: "lastName",
                  label: "Last Name",
                },
                {
                  name: "firstName",
                  label: "First Name",
                },
                {
                  name: "email",
                  label: "Email",
                },
                {
                  name: "role",
                  label: "Role",
                },
                {
                  name: "groupName",
                  label: "Group Name",
                },
                {
                  name: "Actions",
                  label: "Actions",

                  options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <>
                          <Button
                            variant="contained"
                            onClick={() => handleSwitchUser(tableMeta.rowData[3],tableMeta.rowData[0])}
                          >
                            Switch to User
                          </Button>
                        </>
                      );
                    },
                  },
                },
              ]}
              options={{
                selectableRows: "none", // <===== will turn off checkboxes in rows
              }}
            />
          </TabPanel>

          <TabPanel value={tabValue} index={configurationIndex}>
            <Box sx={{ width: '80%',ml:5 }}>
              <ConfigAccordian configurationArray={configurationArray} handleOpenDialog={(item,rowData) =>handleOpenDialog(item,rowData)} handleDelete={handleDelete}/>
            </Box>
          </TabPanel>

        </Box>
      </>
      {viewRoleState?.showViewRole && (
        <RoleView
          handleClose={closeViewRoleDialog}
          {...viewRoleState}
          groupId={userGroupId}
        />
      )}
      {viewReports.openFlag && (
        <ReportsView
          handleClose={closeViewReportsDialog}
          viewReports={viewReports}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
        />
      )}
      {viewGroupState?.showViewGroup && (
        <GroupView
          handleClose={closeViewGroupDialog}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          {...viewGroupState}
        />
      )}
      {viewDocuments?.isDocumentOpen && (
        <DocumentDialog
          handleClose={closeDocumentDialog}
          {...viewDocuments}
          setRefreshData={setRefreshData}
        />
      )}
      {dialogOpen.flag && <ConfigurationDialog dialogOpen={dialogOpen} handleCloseDialog={(e, reason) => handleCloseDialog(e,reason)} formState={formState} setFormState={setFormState} handleChange={handleChange} formErrorState={formErrorState} handleSubmit={handleSubmit} />}
      <ToastContainer />
    </Container>
  );
}
