export const typeObject = {
    "Study Types": "studyType",
    "HST Brands": "brandName",
    "HST Devices": "deviceName",
    "DME Brands": "brandName",
    "DME Models": "model",
    "File Categories": "category",
    "Reasons for Rescheduling": "reason",
    "Reason for Canceling Study": "reason",
    "Reasons for Incomplete Appt": "reason",
    "Reasons for Authorization Denial": "reason",
    "Reasons to Decline Study": "reason",
    "SMS / Email Content": "content"
};

export const configurationObject = {
    'Study Types': { idField: 'studyTypeId', tableName: 'studyTypes' },
    'HST Brands': { idField: 'hstBrandId', tableName: 'hstBrands' },
    'HST Devices': { idField: 'hstDeviceId', tableName: 'hstDevices' },
    'DME Brands': { idField: 'dmeBrandId', tableName: 'dmeBrands' },
    'DME Models': { idField: 'dmeModelId', tableName: 'dmeModels' },
    'File Categories': { idField: 'fileCategoryId', tableName: 'fileCategories' },
    'Reasons for Rescheduling': { idField: 'reasonReschedulingId', tableName: 'reasonApptRescheduling' },
    'Reason for Canceling Study': { idField: 'reasonCancelingId', tableName: 'reasonApptCanceling' },
    'Reasons for Incomplete Appt': { idField: 'reasonIncompleteStudyId', tableName: 'reasonIncompleteStudy' },
    'Reasons for Authorization Denial': { idField: 'reasonAuthDenialId', tableName: 'reasonAuthDenial' },
    'Reasons to Decline Study': { idField: 'reasonDecliningId', tableName: 'reasonDeclining' },
    'SMS / Email Content': { idField: 'contentId', tableName: 'contents' },
};