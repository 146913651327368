import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updateConfigurationTab } from '../../../store/slices/adminPathSlice';
import { useDispatch, useSelector } from 'react-redux';

const ConfigAccordian = ({ configurationArray, handleOpenDialog, handleDelete }) => {

  const { expanded } = useSelector((state) => state.adminPathSelector);
  const dispatch = useDispatch();

  const yesNoOption = { 0: "No", 1: "Yes" }

  const handleChange = (panel) => (event, isExpanded) => {
    dispatch(updateConfigurationTab(isExpanded ? panel : false));
  };

  const createActionColumn = (itemName) => ({
    name: "actions",
    label: "Actions",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => renderActions(itemName, tableMeta),
      setCellProps: () => ({ style: { width: '200px', padding: '10px' } })
    }
  });

  // Dynamic column configuration based on the item name
  const getColumns = (itemName) => {

    switch (itemName) {
      case 'Study Types':
        return [
          { name: "studyType", label: "Study Types", },
          { name: "inLab", label: "In Lab", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[1]]}</> } },
          { name: "multiNight", label: "Multi Night", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[2]]}</> } },
          { name: "hst", label: "HST", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[3]]}</> } },
          { name: "dme", label: "DME", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[4]]}</> } },
          createActionColumn("Study Types")
        ];
      case 'HST Brands':
        return [
          { name: "brandName", label: "Brand Name" },
          createActionColumn("HST Brands")
        ];
      case 'HST Devices':
        return [
          { name: "deviceName", label: "HST Devices"},
          { name: "brandName", label: "Brand Name"},
          createActionColumn("HST Devices")
        ];
      case 'DME Brands':
        return [
          { name: "brandName", label: "Brand Name" },
          createActionColumn("DME Brands")
        ];
      case 'DME Models':
        return [
          { name: "model", label: "DME Model" },
          { name: "brandName", label: "DME Brand" },
          createActionColumn("DME Models")
        ];
      case 'File Categories':
        return [
          { name: "category", label: "File Category" },
          { name: "patientPortal", label: "Show on the Patient Portal", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[1]]}</> } },
          createActionColumn("File Categories")
        ];
      case 'Reasons for Rescheduling':
        return [
          { name: "reason", label: "Reason for Rescheduling" },
          createActionColumn("Reasons for Rescheduling")
        ];
      case 'Reason for Canceling Study':
        return [
          { name: "reason", label: "Appt. Cancellation Reason" },
          { name: "inLab", label: "In Lab", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[1]]}</> } },
          { name: "hst", label: "HST", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[2]]}</> } },
          { name: "dme", label: "DME", options: { customBodyRender: (value, tableMeta, updateValue) => <>{yesNoOption[tableMeta?.rowData[3]]}</> } },
          createActionColumn("Reason for Canceling Study")
        ];
      case 'Reasons for Incomplete Appt':
        return [
          { name: "reason", label: "Reason for Incomplete Appointment" },
          createActionColumn("Reasons for Incomplete Appt")
        ];
      case 'Reasons for Authorization Denial':
        return [
          { name: "reason", label: "Reason for Auth Denial" },
          createActionColumn("Reasons for Authorization Denial")
        ];
      case 'Reasons to Decline Study':
        return [
          { name: "reason", label: "Reason to Decline Appt." },
          createActionColumn("Reasons to Decline Study")
        ];
      case 'SMS / Email Content':
        return [
          { name: "category", label: "Category" },
          { name: "content", label: "Content", options: { customBodyRender: (value, tableMeta, updateValue) => <div dangerouslySetInnerHTML={{ __html: tableMeta?.rowData[1] }}></div> } },
          createActionColumn("SMS / Email Content")
        ];
      default:
        return [];
    }
  };

  const renderActions = (itemName, tableMeta) => {
    // Get rowData from tableMeta if needed (this is passed automatically)
    const rowData = configurationArray.find((item) => item.name === itemName)?.data[tableMeta.rowIndex]
    return (
      <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
        <Button
          variant="contained"
          onClick={() => handleOpenDialog(itemName, rowData)} 
          sx={{ mr: 3.5 }}
        >
          Edit
        </Button>
        <Button variant="contained" sx={{ ml: -1 }} onClick={() => handleDelete(itemName,rowData)}>
          Delete
        </Button>
      </Box>
    );
  };

  return (
    <>
      {configurationArray?.sort((a, b) => a.name.localeCompare(b.name)).map((item, index) => (
        <Accordion
          key={index}
          onChange={handleChange(index)}
          expanded={expanded === index}
          className="visits-accordian"
          sx={{marginBottom:1}}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ backgroundColor: "#f4f2f7", "& .Mui-expanded": {
              margin:"0 !important"
            }}}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Typography variant="strong">{item.name}</Typography>
              {expanded === index && <Button
                variant="outlined"
                size="medium"
                onClick={(e) => { e.stopPropagation(); handleOpenDialog(item.name) }}
                sx={{ marginRight: 2 }}
              >
                + ADD {item.name}
              </Button>}
            </Box>
          </AccordionSummary>
         
          <AccordionDetails>
            <Box
              sx={{
                width: "100%",
                maxWidth: "800px",
                margin: "0 auto",
                overflowX: "auto",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
            >
             {expanded === index && 
             <MUIDataTable
                data={item.data}
                columns={getColumns(item.name)}
                options={{
                  selectableRows: "none",
                  responsive: "standard",
                  setTableProps: () => ({
                    sx: {
                      "& .MuiTableCell-root": {
                        padding: "10px 16px",
                      },
                    },
                  }),
                }}
              />}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </>

  );
};

export default ConfigAccordian;