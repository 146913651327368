import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { number } from "yup";
import {
  dmeOrderPostData,
  getHeaderData,
  orderPostData,
  patientsPostData,
  profilePost,
  pulldownsPostData,
} from "../../../axios/api";
import { useAuth, useAxios } from "../../../hooks/auth";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import moment from "moment";
import { Viewer } from "@react-pdf-viewer/core";
import signOrderHTMLString from "../../../utils/signOrderHTMLString";
import { Buffer } from "buffer";
import "@react-pdf-viewer/core/lib/styles/index.css";
import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import roleAndPrivilegesFnc from "../../../utils/roleAndPriviledges";
import SignDmeOrderHTMLString from "../../../utils/SignDmeOrderHTMLString";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.js`;
function SignedOrder({
  formState,
  fallsAsleepCheckBox,
  isSignOrderDisable,
  setIsSaveButtonDisabled,
  visitDateState,
  setIsOrderSignedSucess,
  saveOrder,
  setOpen,
  open,
  selectedCheckbox,
  setFormState,
  isDmeOrder,
  isOrderAuthorityPresent,
  physicianName,
  physicianId
}) {
  const axios = useAxios();
  const {
    cookies: { token },
  } = useAuth();
  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [inssuranceData, setInssuranceData] = useState({
    primaryInsurance: "",
    secondaryInsurance: "",
    interpPhysName: "",
  });
  const [orderAuthority, setOrderAuthority] = useState(0)

  if (physicianName) {
    let result = physicianName.split(" ");
  
    physicianName = {
      firstName: result[0] ,
      lastName: result[1],
    };

  }


  const [pdfURLState, setPdfURLState] = useState("");
  const [pinState, setPinState] = useState({
    pin: "",
    error: false,
  });
  const { roleAndPriviledge } = roleAndPrivilegesFnc(token);

  useEffect(() => {
    async function setDataFirstTime() {
      pulldownsPostData(axios, "insurancePulldown")
        .then((res) => {
          if (res?.data) {
            const primaryInsurance = res?.data?.find(
              (item) => item?.insuranceId === formState?.primaryInsuranceId
            )?.planname;
            const secondaryInsurance = res?.data?.find(
              (item) => item?.insuranceId === formState?.secondaryInsuranceId
            )?.planname;
            setInssuranceData({
              ...inssuranceData,
              primaryInsurance,
              secondaryInsurance,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //   pulldownsPostData(axios,"physicianPulldown")
      //   .then((res)=>{
      //     if (res?.data) {
      //     let interpPhysName = res?.data?.find(item => item?.userId === formState?.interpPhysId);
      //     interpPhysName= `${interpPhysName?.firstName} ${interpPhysName?.lastName}`
      //     setInssuranceData({
      //         ...inssuranceData, interpPhysName
      //     })
      //     }
      //   })
      //   .catch((err)=>{
      //     console.log(err);
      //   })
    }
    if (open) {
      setDataFirstTime();
    }
  }, [open]);

  useEffect(() => {
    if(formState?.physicianId){
      profilePost(axios, "checkOrderAuthorityUser", { physicianId: formState?.physicianId })
          .then((res) => {
              setOrderAuthority(res?.checkOrderAuthority)
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }, [formState?.physicianId])
  
  const hamdleChangePin = (e) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setPinState({
        ...pinState,
        pin: value.slice(0, 4),
        error: false,
      });
    }
  };
  const handleSignedFileUpload = (dataUrl) => {
    let fileName;
    if (isDmeOrder) fileName = `SignedDmeOrder.pdf`;
    else fileName = `SignedOrder.pdf`;
    const payload = {
      file: dataUrl,
      fileName: fileName,
      patientId: selectedPatient?.patientId || formState.patientId,
      signedOrder: 1,
      stageId: formState.stageId,
      orderId: formState.orderId,
      dmeOrderId: formState.dmeOrderId,
      physicianId: formState?.physicianId,
    };
    patientsPostData(axios, "uploadFile", payload)
      .then((res) => {
        if (res.data.response === "1") {
          // notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => notificationToastError(err?.message))
      .finally(() => handleClose());
  };
  const base64ToPdf = (base64String,fileType) => {
    let typeFile = fileType === "pdf" ? "application/pdf" : "image/png"
    const buffer = Buffer.from(base64String, "base64");
    const url = URL.createObjectURL(
      new Blob([buffer], { type: typeFile })
    );
    return url;
  };
  const generatePdf = (signature,headerDetail,headerLogo) => {
    let htmlString;
    if (isDmeOrder) {
      htmlString = SignDmeOrderHTMLString({
        signature,
        formState,
        visitDateState,
        fallsAsleepCheckBox,
        inssuranceData,
        physicianName: roleAndPriviledge?.userName,
        selectedCheckbox,
        headerDetail,
        headerLogo
      });
    } else {
      htmlString = signOrderHTMLString({
        signature,
        formState,
        visitDateState,
        fallsAsleepCheckBox,
        inssuranceData,
        physicianName: physicianName || roleAndPriviledge?.userName,
        selectedCheckbox,
        headerDetail,
        headerLogo
      });

    }

    // const tempDiv = document.createElement('div');
    // tempDiv.innerHTML = htmlString; 
    // document.body.appendChild(tempDiv);
    // const contentHeight = (tempDiv.clientHeight * 0.75) ; // px to pt converter
    // const contentWidth = (tempDiv.clientWidth * 0.75); // px to pt converter
    // document.body.removeChild(tempDiv);
    // console.log('contentHeight',contentWidth, contentHeight)
    const pageSize = isDmeOrder ? [845, 1000] : "a3"
    const pdf = new jsPDF("p", "pt", pageSize);

    pdf.html(htmlString, {
      callback: function (file) {
        let dataUrl = pdf.output("datauristring");
        dataUrl = dataUrl?.split(";");
        dataUrl = [dataUrl[0], dataUrl[2]]?.join(";");
        if (dataUrl) {
          handleSignedFileUpload(dataUrl);
          let base64 = pdf.output("datauristring");
          base64 = base64?.split(",")[1];
          const pdfUrl = base64ToPdf(base64);
          setPdfURLState(pdfUrl);
          setOpenPdfModal(true);
          setIsSaveButtonDisabled(true);
        }
      },
      html2canvas: {
        scale: 0.8,
      },
      // margin: [10, 10],
    });
  };
  const handleConfirmPin = async () => {
    if (pinState.pin?.length < 4) {
      setPinState({
        ...pinState,
        error: true,
      });
    } else if (pinState.pin?.length === 4) {
      const payload = {
        pin: pinState.pin,
        userId: isOrderAuthorityPresent ? roleAndPriviledge?.id : "" ,
        physicianId: physicianId
      };
      if (isDmeOrder) {
        await getHeaderData(axios, "getDmeOrderHeader", { dmeOrderId : formState.dmeOrderId  })
          .then((response) => {
            if (response) {
              dmeOrderPostData(axios, "signDmeOrder", payload)
                .then((res) => {
                  if (res?.data[0]?.signature) {
                    notificationToastSuccess("Order signed successfully");
                    if(response[0][0]?.logoFileId){
                      patientsPostData(axios, "downloadFile", {
                        id:response[0][0]?.logoFileId, tableName: "storedFiles",idColumn: "fileId",
                      }).then((imgres) => {
                        if (imgres.data.file) {
                            generatePdf(res?.data[0]?.signature, response, imgres?.data?.file);
                        } else {
                          notificationToastError("File was not downloaded due to issue.");
                        }
                      }).catch((err) => notificationToastError(err?.message))
                    }else{
                      generatePdf(res?.data[0]?.signature, response);
                    }
                  } else if (res?.data?.response === "0") {
                    notificationToastError(res?.data?.message);
                  } else if (!res?.data[0]?.signature) {
                    notificationToastError(
                      "Signature is missing in the profile page"
                    );
                  }
                })
                .catch((err) => {
                  notificationToastError(err?.message);
                })
                .finally(() => handleClose());
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          })
          .finally(() => handleClose());
      } else {
        await getHeaderData(axios, "getOrderHeader", {orderId: formState.orderId})
          .then((response) => {
            if(response){
              orderPostData(axios, "signOrder", payload)
              .then((res) => {
                if (res?.data[0]?.signature) {
                  notificationToastSuccess("Order signed successfully");
                  if(response[0][0]?.logoFileId){
                    patientsPostData(axios, "downloadFile", {
                      id:response[0][0]?.logoFileId, tableName: "storedFiles",idColumn: "fileId",
                    }).then((imgres) => {
                      if (imgres.data.file) {
                          generatePdf(res?.data[0]?.signature, response, imgres?.data?.file);
                      } else {
                        notificationToastError("File was not downloaded due to issue.");
                      }
                    }).catch((err) => notificationToastError(err?.message))
                  }else{
                    generatePdf(res?.data[0]?.signature, response);
                  }
                } else if (res?.data?.response === "0") {
                  notificationToastError(res?.data?.message);
                } else if (!res?.data[0]?.signature) {
                  notificationToastError(
                    "Signature is missing in the profile page"
                  );
                }
              })
              .catch((err) => {
                notificationToastError(err?.message);
              })
              .finally(() => handleClose());
            }
          })
          .catch((err) => {
            notificationToastError(err?.message);
          })
          .finally(() => handleClose());
      }
    }
  };
  const hanldeSignOrder = (e) => {
    saveOrder(e, 1);
  };
  const downloadPdf = () => {
    let fileName;
    if (isDmeOrder) fileName = `signedDmeOrder.pdf`;
    else fileName = `signedOrder.pdf`;
    const link = document.createElement("a");
    link.href = pdfURLState;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // revoke the temporary URL to free up memory
    URL.revokeObjectURL(pdfURLState);
  };
  const handleClose = () => {
    setOpen(false);
    setPinState({
      pin: "",
      error: false,
    });
  };
  const handleClosePdf = () => {
    setOpenPdfModal(false);
    setIsOrderSignedSucess(true);
  };
  return (
    <>
      <Button
        id="signOrderBtn"
        onClick={hanldeSignOrder}
        disabled={isSignOrderDisable}
        variant="contained"
      >
        {isDmeOrder ? "Sign DME Order" : orderAuthority ?  "Sign Order as Physician" : "Sign Order"}
      </Button>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <Box sx={{ padding: "20px", maxWidth: "210px" }}>
            <Typography variant="h6" sx={{ fontWeight: "700", color: "#000" }}>
              Enter Pin
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="pin"
              type="number"
              fullWidth
              variant="standard"
              sx={{ padding: "16px 0px" }}
              value={pinState.pin}
              onChange={hamdleChangePin}
              helperText={
                pinState.error && !pinState.pin
                  ? "Required"
                  : "Enter 4 digits pin"
              }
              error={pinState.error}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleConfirmPin} variant="contained">
                Confirm
              </Button>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {openPdfModal && (
        //  pdfURLState &&
        <Dialog onClose={handleClosePdf} open={openPdfModal} maxWidth="xl">
          <Box sx={{ padding: "20px", width: "1100px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <DownloadIcon sx={{ cursor: "pointer" }} onClick={downloadPdf} />
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClosePdf} />
            </Box>
            {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
            <Viewer
              fileUrl={pdfURLState}
              // width="100%" // set the width to 600 pixels
              // height={400} // set the height to 800 pixels
              //  defaultScale={0.8}
              defaultScale={1.2}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
}

export default SignedOrder;
