import { createSlice } from "@reduxjs/toolkit";


export const adminPathSlice = createSlice({
  name: "adminPathData",
  initialState: {
    tabValue: 0,
    tabName:"Users",
    expanded:null
  },
  reducers: {
    updateAdminPath: (state, action) => {
      state.tabValue = action.payload;
    },
    updateAdminTabName: (state, action) => {
      state.tabName = action.payload;
    },
    updateConfigurationTab: (state, action) => {
      state.expanded = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAdminPath, updateAdminTabName,updateConfigurationTab } = adminPathSlice.actions;

export default adminPathSlice.reducer;