import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import { LinearProgressWithLabel } from '../../../../pages/patients/components/FileUpload/FileUpload';
import classes from '../../../../pages/patients/components/FileUpload/fileUpload.module.css'
import { notificationToastError } from '../../../../utils/notification';

const Documentupload = ({ setFormState,setIsSaveButtonDisabled=()=>{},formErrorState={},setFormErrorState=()=>{},formState={},title,setIsDisable=()=>{}}) => {
    const isMobile = useMediaQuery('(max-width:800px)')
    const [uploadProgress, setUploadProgress] = useState(0);
    const uploadFileFlag = title === "Upload Signed Order" || title === "UPLOAD CORRECTED SIGNED ORDER";
    
    useEffect(() => {
        if (formState?.fileName) {
            setUploadProgress(100)
        }
    }, [formState])
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(uploadFileFlag && file?.type !== "application/pdf"){
            notificationToastError("Your File is not PDF. Please Upload PDF File.");
            return ;
        }
        // setSelectedFile(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                setUploadProgress(progress);
            }
        };
        reader.onloadend = () => {
            setIsSaveButtonDisabled(false)
            setIsDisable(true)
            setFormErrorState({
                ...formErrorState,
                file:""
            })
            setFormState((prevState) => ({
                ...prevState,
                file: reader?.result,
                fileName : file?.name
            }));
        }

    };
    return (
        <>
            <Box className={classes.fileUploadWraper} sx={{ flexDirection: isMobile ? "column" : "row", width: uploadFileFlag && !formState?.fileName ? "650px" : "auto", alignItems: "baseline" }}>
            <Button variant="contained" component="label" sx={{ minWidth: "125px" }}  >
                {title ? title : "Attach File"}
                <input type="file" 
                hidden
                    onChange={handleFileChange}
                />
            </Button>
            {uploadFileFlag && !formState?.fileName ? 
            <Typography>(Please Upload only PDF File.)</Typography> :""}

            <Box className={classes.progressBarWrapper}>
                {
                    uploadProgress > 0 &&
                    <LinearProgressWithLabel sx={{ height: "20px", borderRadius: "20px" }} value={uploadProgress} />
                }
            </Box>

            {formState?.fileName &&
                <Box className={classes.fileNameWrapper}>
                    <Typography color="primary"  >{formState?.fileName}</Typography>
                </Box>
            }
        </Box>
         {
            formErrorState?.file?.error &&
            <Typography sx={{color:'#d32f2f',margin:"8px 0px"}}>Please choose file</Typography>
        }
        </>
       
    )
}

export default memo(Documentupload)