import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import DMEBrandPulldown from '../../misc/pulldowns/DMEBrandPulldown';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toolbarOption } from './editorOption';
import { typeObject } from './ConfigurationObject';
import GeneralPulldown from '../../misc/pulldowns/GeneralPulldown';

const ConfigurationDialog = ({ dialogOpen, handleCloseDialog, handleSubmit, formErrorState, formState, setFormState, handleChange }) => {

  // Initialize formState with default values or values from dialogOpen
  const data = dialogOpen?.rowData || [];
  const blocks = data?.content ? convertFromHTML(data.content) : { contentBlocks: [], entityMap: {} };
  const [editorState, setEditorState] = useState(() =>
    data?.content ? EditorState.createWithContent(ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap))
      : EditorState.createEmpty())
  const [editorHTML, setEditorHTML] = useState("");

  useEffect(() => {
    // Update formState when dialogOpen changes (if needed)
    setFormState((prevState) => ({
      ...prevState,
      ...data
    }));
  }, [dialogOpen]);

  const onEditorStateChange = (editorState) => {
    const editorHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      null,
      false,
    );
    setEditorState(editorState);
    setEditorHTML(editorHTML);
    setFormState({ ...formState, content: editorHTML })
  }

  return (
    <Dialog open onClose={handleCloseDialog} maxWidth="sm" fullWidth disableEscapeKeyDown>
      <DialogTitle>{dialogOpen?.rowData ? `Edit ${dialogOpen?.name}` : `Add ${dialogOpen?.name}`}</DialogTitle>
      <DialogContent>
        {/* Main text field */}
        {dialogOpen?.name !== "SMS / Email Content" ? (
          <TextField
            id={typeObject[dialogOpen?.name]}
            label={dialogOpen?.name}
            name={typeObject[dialogOpen?.name]}
            value={formState[typeObject[dialogOpen?.name]] || ''} 
            onChange={handleChange}
            variant="standard"
            required
            fullWidth
            error={formErrorState[typeObject[dialogOpen?.name]]?.error && !formState[typeObject[dialogOpen?.name]]}
            helperText={formErrorState[typeObject[dialogOpen?.name]]?.error && !formState[typeObject[dialogOpen?.name]] ? `${dialogOpen?.name} is required` : ''}
            sx={{ marginBottom: 2 }}
          />
        ) : null}

        {/* Fields for Study Types */}
        {dialogOpen?.name === "Study Types" && (
          <>
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="inLab"
                      checked={Boolean(formState?.inLab)} 
                      onChange={handleChange}
                      disabled={formState?.hst}
                    />
                  }
                  label="In-Lab"
                />
              </Grid>
              {formState?.inLab ? <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="multiNight"
                      checked={Boolean(formState?.multiNight)}
                      onChange={handleChange}
                    />
                  }
                  label="Multi-Night"
                />
              </Grid> : ""}
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hst"
                      checked={Boolean(formState?.hst)} 
                      onChange={handleChange}
                      disabled={formState?.inLab}
                    />
                  }
                  label="HST"
                />
              </Grid>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="dme"
                      checked={Boolean(formState?.dme)}  
                      onChange={handleChange}
                    />
                  }
                  label="DME"
                />
              </Grid>
            </Grid>
          </>
        )}

        {/* HST brand pulldown */}
        {dialogOpen?.name === "HST Devices" && ( <GeneralPulldown
          key="hstBrandId"
          formState={formState}
          updateInput={handleChange}
          formErrorState={formErrorState}
          attributeName="hstBrandId"
          dbKeyColumn="hstBrandId"
          style={{ width: "100%" }}
          pulldownApiName="hstBrands"
          itemName="brandName"
          labelName="Select HST Brand"
          errorMessage="HST Brand is required"
        />)}

        {/* DME Models */}
        {dialogOpen?.name === "DME Models" && (
          <DMEBrandPulldown
            key="dmeBrandId"
            formState={formState}
            updateInput={handleChange}
            formErrorState={formErrorState}
            attributeName="dmeBrandId"
            style={{ width: "100%" }}
            pulldownApiName="dmeBrands"
            labelName="DME Brand"
            itemName="brandName"
            errorMessage="DME Brand is required"
          />
        )}

        {/* File Categories */}
        {dialogOpen?.name === "File Categories" && (
          <FormControlLabel
            control={
              <Checkbox
                name="patientPortal"
                checked={formState.patientPortal || false}  
                onChange={handleChange}
              />
            }
            label="Show on Patient Portal"
            sx={{ mt: 1 }}
          />
        )}

        {/* Cancellation Reasons */}
        {dialogOpen?.name === "Reason for Canceling Study" && (
          <>
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="inLab"
                      checked={Boolean(formState?.inLab)} 
                      onChange={handleChange}
                    />
                  }
                  label="In-Lab"
                />
              </Grid>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hst"
                      checked={Boolean(formState?.hst)}  
                      onChange={handleChange}
                    />
                  }
                  label="HST"
                />
              </Grid>
              <Grid item lg={3} md={4} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="dme"
                      checked={Boolean(formState?.dme)}  
                      onChange={handleChange}
                    />
                  }
                  label="DME"
                />
              </Grid>
            </Grid>
          </>
        )}

        {/* SMS / Email Content text editor */}
        {dialogOpen?.name === "SMS / Email Content" ? (
          <Box>
            <FormControl fullWidth variant="standard" sx={{ marginTop: 2 }}>
              <InputLabel id="category-label" error={formErrorState?.category?.error && !formState?.category} >Category </InputLabel>
              <Select
                labelId="category-label"
                id="category"
                name="category"
                value={formState?.category || ''} 
                onChange={handleChange}
                label="category"
                required
                error={formErrorState?.category?.error && !formState?.category}
              >
                <MenuItem value="SMS">SMS</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
              </Select>
              {formErrorState?.category?.error && !formState?.category && (
                <Typography variant="subtitle2" fontSize={13} mt={0.5} color={"error"}>
                  Category Field is required.
                </Typography>
              )}
            </FormControl>
            <Box sx={{ mt: 2, border: "1px solid black", p: 1 }}>
              <Editor
                editorState={editorState}
                toolbar={toolbarOption}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              />
            </Box>
          </Box>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleSubmit(formState)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurationDialog;